@use "../config/" as *;
.static-content,
.waf-static,
.waf-detail {
	.title {
		@extend %py-3;
		@extend %font-20-pb;
		@extend %neutral-800;
	}
	p,
	.text,
	li {
		line-height: 2rem;
		@extend %mb-3;
		@extend %font-16-pm;
		@extend %neutral-800;
	}
	p {
		@extend %mb-3;
	}
	.meta {
		@extend %mb-2;
		@extend %font-14-pm;
		@extend %neutral-800;
	}
	a {
		text-decoration: underline;
		@extend %neutral-800;
		@extend %font-14-pb;
	}
	strong {
		@extend %font-14-pb;
	}
	.sub-title,
	.highlight,
	.detail-sub-title {
		line-height: 2.6rem;
		@extend %font-14-pb;
		@extend %neutral-800;
		@extend %mb-2;
	}
	// .blockquote {
	// 	border-bottom: .1rem solid hsl(var(--hsl-neutral-1000) / .1);
	// 	border-top: .1rem solid hsl(var(--hsl-neutral-1000) / .1);
	// }
	ul,
	ol {
		list-style: initial;
		@extend %pl-3;
		@extend %mb-3;
		li {
			@extend %mb-3;
			&::marker {
				@extend %font-14-pb;
				@extend %neutral-800;
			}
		}
	}
	thead {
		tr {
			th {
				@extend %font-14-pb;
				@extend %neutral-50;
			}
		}
	}
	.bullet-list {
		list-style: none;
		@extend %p-0;
		@extend %mt-6;
		li {
			@extend %relative;
			@extend %pl-4;
			&::before {
				content: "";
				@include square(0.5rem);
				@include position(0, null, null, 0, absolute);
				@extend %primary-800-bg;
				@extend %circle-radius;
			}
			&::after {
				content: "";
				width: .1rem;
				@include position(0, null, null, .2rem, absolute);
				@extend %h-100;
				@extend %neutral-900-bg-4;
			}
		}
	}
	tbody {
		td {
			@extend %neutral-800;
			@extend %font-14-pr;
		}
	}
	table,
	th,
	td {
		border: 0.1rem solid hsl(var(--hsl-neutral-1000) / .1);
		border-collapse: collapse;
	}
	.number-list > li {
		list-style-type: decimal;
		@extend %font-14-pb;
		@extend %neutral-800;
	}
	.alpha-type > li {
		list-style-type: lower-alpha;
	}
	.roman-list > li {
		list-style-type: lower-roman;
	}
	.no-list-style > li {
		list-style-type: none;
	}
	.lower-roman {
		list-style-type: lower-roman;
	}
	.upper-roman {
		list-style-type: upper-roman;
	}
	.disc-list {
		list-style-type: disc;
	}
	.circle-list {
		list-style-type: circle;
	}
	.lower-alpha {
		list-style-type: lower-alpha;
	}
	.upper-alpha {
		list-style-type: upper-alpha;
	}
	video {
		width: 100%;
	}
	img,
	video {
		@extend %half-radius;
	}
	table {
		@extend %my-2;
	}
	.article-body {
		@extend %p-3;
		border-radius: var(--full-radius);
		@extend %neutral-50-bg;
	}
}
body {
	counter-reset: section;
}
.waf-static {
	.waf-accordion {
		&.active {
			@extend %neutral-100-bg;
			@extend %half-radius;
		}
	}
	.waf-accordion-trigger {
		border-bottom: .1rem solid clr(neutral-300);
		@extend %w-100;
		@extend %text-left;
		@extend %flex-sb-c;
		@extend %gap-3;
		.arrow-icon {
			flex-shrink: 0;
			width: 3rem;
			height: 3rem;
			@extend %relative;
			@extend %circle-radius;
			&::after,
			&::before {
				content: "";
				height: 0.15rem;
				position: absolute;
				inset: 0;
				@extend %rounded-radius;
				@extend %m-auto;
				@extend %w-50;
				@extend %neutral-900-bg;
			}
			&::after {
				rotate: 90deg;
				transition: 300ms rotate;
			}
		}
		&[aria-expanded=true] {
			.arrow-icon {
				&::before,
				&::after {
					@extend %tertiary-600-bg;
				}
				&::after {
					rotate: 0deg;
				}
			}
		}
	}
	.waf-accordion-panel {
		@extend %p-3;
		@extend %relative;
		&::after {
			content: "";
			height: calc(100% - var(--space-6));
			width: var(--_accordion-line, .2rem);
			@include position(1.5rem, null, null, 0, absolute);
			@extend %tertiary-600-bg;
		}
	}
	.accordion {
		&-wrap {
			@extend %mt-4;
		}
		&-title {
			@extend %p-3;
			@extend %font-14-pb;
			&::before {
				counter-increment: section;
				content: counter(section) ". ";
			}
		}
	}
}
.waf-error-page {
	@extend %py-6;
	@extend %px-0;
	&::before {
		content: '';
		z-index: var(--z-error-bg);
		pointer-events: none;
		@extend %pos-tl;
		@extend %w-100;
		@extend %h-100;
		@include background(null, "pattern/mobile/error-bg.png", center / cover no-repeat);
	}
	.content {
		@extend %pt-4;
		@extend %px-3;
		@extend %text-center;
	}
	.title {
		@extend %mb-4;
		@extend %tertiary-500;
		@extend %font-22-pb;
	}
	.text {
		@extend %font-12-pm;
		@extend %pure-white-900;
		&:last-child {
			@extend %mt-2;
		}
	}
	.link {
		@extend %tertiary-500;
	}
}
.waf-csr {
    .csr-head {
        @extend %mb-4;
    }
	.table {
		overflow-x: auto;
		overflow-y: hidden;
        @extend %mb-4;
		&-row {
			justify-content: unset;
		}
		&-data {
			width: 35%;
			flex-shrink: 0;
			@extend %px-2;
			&.csr-name {
				text-align: left;
				justify-content: flex-start;
				width: 25rem;
				.text {
					@extend %capitalize;
					@extend %font-14-pb;
				}
			}
			.text {
				@extend %mb-0;
			}
		}
	}
	.table-head {
		.table-data {
			background: linear-gradient(91.83deg, var(--secondary-800) 0%, var(--primary-700) 100%);
			background-attachment: fixed;
			height: 4.5rem;
			&:first-child {
				border-top-left-radius: var(--half-radius);
			}
			&:last-child {
				border-top-right-radius: var(--half-radius);
			}
			&.csr-name {
				.text {
					@extend %uppercase;
				}
			}
			.text {
				@extend %font-14-pb;
			}
		}
	}
	.table-body {
		.table-data {
			height: 5.5rem;
			@extend %relative;
			&::before {
				content: '';
				height: .1rem;
				@extend %pos-bl;
				@extend %w-100;
				@extend %neutral-200-bg;
			}
			.text {
				@extend %neutral-800;
				@extend %font-14-pm;
			}
		}
	}
}
@media screen and (min-width: $tablet-min-width) {
	.waf-static,
	.static-content,
	.waf-detail {
		--_accordion-line: 0.4rem;
		.accordion-title {
			padding-block: var(--space-4);
		}
		.waf {
			&-body {
				padding: 4rem 1rem;
			}
			&-head {
				.title {
					font-size: 2.8rem;
				}
			}
		}
		.title {
			font-size: 2.6rem;
		}
		.article-body {
			padding: var(--space-6);
		}
		.sub-title,
		.highlight {
			font-size: 1.8rem;
		}
		p {
			font-size: 1.4rem;
			line-height: 1.8;
		}
		strong {
			font-size: 1.6rem;
		}
		ol,
		ul {
			li {
				font-size: 1.4rem;
			}
		}
		.caption {
			font-size: 1.3rem;
		}
		.number-list > li {
			font-size: 1.8rem;
			&::marker {
				font-size: 1.8rem;
			}
		}
	}
	.waf-error-page {
		&::before {
			@include background(null, "pattern/error-bg.png", center / cover no-repeat);
		}
		.layout-wrapper {
			display: flex;
			align-items: center;
			gap: var(--space-10);
		}
		.thumbnail {
			width: 50%;
		}
		.content {
			text-align: left;
			padding: var(--space-0);
			width: 50%;
		}
		.title {
			font-size: 3.2rem;
		}
		.text {
			font-size: 1.6rem;
			line-height: 2.4rem;
		}
	}
	.waf-csr {
		.table-data {
			width: 25%;
			&.csr-name {
				width: 20rem;
			}
		}
	}
}
@media screen and (min-width: $desktop-min-width) {
	.waf-csr {
		.table-data {
			width: 20%;
			&.csr-name {
				flex: 1;
			}
		}
	}
}